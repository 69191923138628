/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

/* Header Section */
.header {
  background-color: #110000; /* Black background for header */
  padding: 50px;
  color: white;
  
}
.agricultural-products-btn {
  position: absolute;
  top: 10px; /* Adjust this value for the top padding */
  right: 10px; /* Adjust this value for the right padding */
  padding: 10px 20px;
  background-color: #4CAF50; /* Button background color */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.agricultural-products-btn:hover {
  background-color: #45a049; /* Button hover effect */

}
.business-partners-btn {
  position: absolute;
  top: 10px; /* Adjust this value for the top padding */
  right: 200px; /* Adjust this value for the right padding */
  padding: 10px 20px;
  background-color: #4CAF50; /* Button background color */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.business-partners-btn:hover {
  background-color: #45a049; /* Button hover effect */
}
 

.company-licenses-btn {
  position: absolute;
  top: 10px; /* Adjust this value for the top padding */
  right: 373px; /* Adjust this value for the right padding */
  padding: 10px 20px;
  background-color: #4CAF50; /* Button background color */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.company-licenses-btn:hover {
  background-color: #45a049; /* Button hover effect */
}

.gallery-btn {
  position: absolute;
  top: 10px; /* Adjust this value for the top padding */
  right: 552px; /* Adjust this value for the right padding */
  padding: 10px 20px;
  background-color: #4CAF50; /* Button background color */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.galley:hover {
  background-color: #45a049; /* Button hover effect */
}

.logo {
  width: 200px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  margin: 0; /* Remove outer spacing */
  padding: 0; /* Remove internal spacing */
  border: 0; /* Eliminate any borders around the image */
  display: block; /* Ensure no inline spacing */
}


.header h1 {
  margin: 20px 0; /* Space around the heading */
  font-size: 2.5rem; /* Adjust font size as needed */
}

.header p {
  font-size: 1.2rem; /* Adjust font size as needed */
}

/* Cocoa Image Section Below Header */
.hero-image {
  position: relative; /* Ensure correct positioning of the background image */
}

.cocoa_farm_centre-img {
  width: 100%; /* Cover the entire width of the section */
  height: auto; /* Maintain aspect ratio */
  max-height: 500px; /* Limit the height if needed */
  object-fit: cover; /* Ensure the image covers the section */
}

/* Main Content Section Below Cocoa Image */
.main-content {
  padding: 20px;
}

/* Introduction Section */
.intro {
  padding: 50px;
  background-color: #f4f4f4; /* Background color for the intro section */
}

.intro h2 {
  font-size: 2rem;
}

.intro p {
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto;
}

/* About Us Section */
.about {
  padding: 50px;
  background-color: #f4f4f4;
}

.about h2 {
  font-size: 2rem;
}

.about p {
  font-size: 1.1rem;
  max-width: 800px;
  margin: 0 auto;
}

/* Contact Section */
.contact {
  padding: 50px;
}

.contact h2 {
  font-size: 2rem;
}

.contact p {
  font-size: 1.1rem;
}

/* Footer */
.footer {
  background-color: #2E7D32;
  color: white;
  padding: 20px;
  margin-top: 30px;
}
